import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ReviewCard from "./review-card";
import SmallReviewCard from "./small-review-card";
import { Button } from "react-bootstrap";

const Reviews = ({ title }) => {
  const [reviews, setReviews] = useState([]);
  const num = 0;

  useEffect(() => {
    axios
      .get(
        `https://www.local-marketing-reports.com/external/showcase-reviews/widgets/7a105739c741fcc4cec345a1270bd3f9acdc2878`
      )
      .then((res) => {
        const reviewData = res.data.results;
        setReviews(reviewData);
        setReviews(reviewData);
        document.getElementById("loading-spinner").style.display = "none";
      });
  }, [num]);

  return (
    <div className="bg-grey">
      <Container className="py-7">
        <Row className="align-items-center   pb-5 ">
          <Col>
            <h2 className="text-center text-lg-start">
              {title ?? "OUR LATEST GOOGLE REVIEWS"}
            </h2>
          </Col>
        </Row>

        <Col>
          <Row className="g-4" style={{ minHeight: "50vh" }}>
            <Spinner
              id="loading-spinner"
              variant="primary"
              animation="border"
              className="mt-10 mx-auto"
            >
              <span className="sr-only"></span>
            </Spinner>
            {reviews
              .filter((item, index) => index < 6)
              .map((review) => (
                <Col lg={6} xl={4} xs={12} className=" ">
                  <SmallReviewCard review={review} />
                </Col>
              ))}
          </Row>
        </Col>

        <Row>
          <Col className="text-center mt-5 ">
            <Button
              style={{ borderRadius: "0px" }}
              variant="secondary"
              as={Link}
              to="/reviews"
              className="py-3 w-100 w-md-auto mb-4 mb-md-0 px-5 me-4"
            >
              Read more reviews
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reviews;
