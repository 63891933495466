import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ContactForm() {
	const [state, handleSubmit] = useForm("xvoljqqa");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group className="mb-3 " controlId="subject">
						<Form.Control
							value="Contact Us Enquiry"
							className="pb-4 d-none"
							name="subject"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="Subject"
						field="subject"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="name">
						<Form.Control
							className="pb-4"
							name="name"
							placeholder="NAME*"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
					<Form.Group className="mb-3" controlId="email">
						<Form.Control
							className="pb-4"
							name="_replyto"
							type="email"
							placeholder="EMAIL*"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />
					<Form.Group className="mb-3" controlId="telephone">
						<Form.Control
							className="pb-4"
							type="tel"
							name="telephone"
							placeholder="TELEPHONE NUMBER*"
						/>
					</Form.Group>

					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="message">
						<Form.Control
							className="py-3"
							name="message"
							placeholder="MESSAGE*"
							as="textarea"
							rows={5}
						/>
					</Form.Group>

					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>

					<Form.Group className="mb-3" controlId="store-details">
						<Form.Check
							label="We would like to store these details to contact you in the future with our news and offers. Read our full privacy policy here. Please tick to indicate how we can contact you."
							className="w-auto"
							type="checkbox"
							value="true"
							name="store-details"
						/>
					</Form.Group>

					<ValidationError
						prefix="store-details"
						field="store-details"
						errors={state.errors}
					/>

					<div className="text-start">
						<Button
							variant="outline-background"
							className="btn py-3 px-7 w-100 w-md-auto mt-3"
							style={{ borderRadius: "0px" }}
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							Send
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3 " controlId="subject">
				<Form.Control
					value="Contact Us Enquiry"
					className="pb-4 d-none"
					name="subject"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Subject" field="subject" errors={state.errors} />
			<Form.Group className="mb-3" controlId="name">
				<Form.Label>NAME*</Form.Label>
				<Form.Control className="pb-4" name="name" type="text" />
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="email">
				<Form.Label>EMAIL*</Form.Label>
				<Form.Control className="pb-4" name="_replyto" type="email" />
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="mb-3" controlId="telephone">
				<Form.Label>TELEPHONE NUMBER*</Form.Label>
				<Form.Control className="pb-4" type="tel" name="telephone" />
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="message">
				<Form.Label>MESSAGE*</Form.Label>
				<Form.Control className="py-3" name="message" as="textarea" rows={5} />
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			<Form.Group className="mb-3" controlId="store-details">
				<Form.Check
					label="We would like to store these details to contact you in the future with our news and offers. Read our full privacy policy here. Please tick to indicate how we can contact you."
					className="w-auto"
					type="checkbox"
					value="true"
					name="store-details"
				/>
			</Form.Group>

			<ValidationError
				prefix="store-details"
				field="store-details"
				errors={state.errors}
			/>
			{/* <div
				class="g-recaptcha"
				data-sitekey="6LdpZdQgAAAAAMGxG6mYjIg6joW2j_K9jJxjwnLN"
			></div> */}
			<div className="text-start">
				<Button
					variant="outline-background"
					className="btn py-3 px-7 w-100 w-md-auto mt-3"
					style={{ borderRadius: "0px" }}
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					Send
				</Button>
			</div>
		</Form>
	);
}
export default ContactForm;
